.field_action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px 80px 30px;
  overflow: hidden;
}

.field_action_title {
  color: black;
  padding: 20px;
}

.field_action_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 2%;
}

.field_text_container {
  display: flex;
  flex-direction: column;
  width: 55%;
  justify-content: center;
}

.action_field_description {
    font-size: 12px;
    color: #8e8e93;
    width: 98%;
    box-sizing: border-box;
    padding-left: 5px;
    padding-right: 5px;
}

.action_error_text {
  font-size: 12px;
  color: #d30000;
  width: 98%;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
}

.action_success_text {
  font-size: 12px;
  color: #4CD697;
  width: 98%;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
}

.field_action_button_container {
  display: flex;
  justify-content: center;
  height: 40px;
  min-width: 20%;
}

.detail_view_button {
  padding: 5px;
  background-color: #6640ff;
  min-width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  outline: none;
  margin-top: 2%;
  margin-left: 3%;

  border: none;
  cursor: pointer;
}

.detail_view_button_success {
  padding: 5px;
  background-color: #4CD697;
  min-width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  outline: none;
  margin-top: 2%;
  margin-left: 3%;

  border: none;
  cursor: pointer;
}
