.detailview_page {
  width: -webkit-fill-available;
  margin-right: 15px;
  height: 92%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;

  border-radius: 14px;
  padding: 20px 30px 20px 30px;
  background-color: #fff;
  box-sizing: border-box;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  flex-grow: inherit;
}

/* --------------------------------TOP SECTION --------------------------------*/

.top_container {
  display: flex;
  flex-direction: column;
  min-width: 100%;
}

.icon_button_detailview {
  appearance: none;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  align-self: flex-start;
  justify-content: center;
  height: 100%;
}

.go_back_text {
  padding: 0;
  margin: 0;
  justify-self: center;
  align-self: center;
  color: #6e6893;
  font-size: 12px;
}

.title_top {
  color: #000;
  font-weight: 500;
  font-size: 20px;
}

/* --------------------------------FIELDS SECTION--------------------------------*/

.scroll_container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  min-width: 100%;
}

/* total width */
.scroll_container::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scroll_container::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.scroll_container::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

.field_flex_column {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub_container {
  margin-top: 15px;
  margin-bottom: 15px;
}

.sub_title {
  margin-left: 30px;
  font-size: 20px;
  font-weight: 400;
  width: auto;
  border-bottom: 1.5px solid #6640ff;
  display: inline-block;
  vertical-align: top;
}

.actions_button_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  width: 20%;
  min-width: 20%;
  max-width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.action_update_button {
  min-width: 100%;
  background: #6640ff;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-bottom: 5%;
  margin-right: 5%;
}

.button_action {
  min-width: 100%;
  background: #fdbc2c;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin-right: 5%;
  margin-bottom: 5%;
}

.field_save_button {
  width: auto;
  background: #4cd697;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.field_create_button {
  width: auto;
  background: #6640ff;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

/* --------------------------------DANGERZONE --------------------------------*/

.dangerzone_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px 30px 30px;
  overflow: hidden;
}

.dangerzone_title {
  font-size: 20px;
  font-weight: 400;
  color: #f44336;
}

.dangerzone_card_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.dangerzone_card {
  padding: 10px 30px 10px 30px;
  background-color: rgba(211, 0, 0, 0.1);
  border-radius: 15px;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dangerzone_column {
  display: flex;
  flex-direction: column;
}

.dangerzone_card_title {
  margin: 5px;
  color: #000;
}

.dangerzone_card_description {
  margin: 5px;
  color: #8e8e93;
}

.dangerzone_button {
  background: #d30000;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
