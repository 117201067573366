.homePage {
    background: #F7F7F7;
    flex: 1;
    width: 100%;
    min-height: 100%;
    position: absolute;
}

.productContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

