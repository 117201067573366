.not_found_page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow_low_opacity);
}

.not_found_page_card {
  width: 50%;
  height: auto;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--white);
}

.not_found_page_title_container {
  padding: 10px;
}

.not_found_page_title_container p {
  font-size: 50px;
}

.not_found_page_text_container {
  padding: 5px;
  margin-bottom: 10px;
}

.not_found_page_text_container p {
  font-size: 15px;
}

.not_found_button_container {
  background: var(--purple);
  color: inherit;
  border: none;
  padding: 8px;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  border-radius: 10px;
  margin-top: 10px;
}

.not_found_button_container p {
  color: var(--white);
  padding: 5px;
  margin: 0;
  cursor: pointer;
}
