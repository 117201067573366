.field_component {
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: flex-start;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.field_container_title {
}

.field_container_input {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.field_title {
  font-size: 20px;
  font-weight: 400;
}

.field_text {
  box-sizing: border-box;
  font-size: 15px;
  width: 74%;
}

.read_only_text {
  margin: 0;
  color: #d30000;
  font-size: 10px;
}

.required_text {
  margin: 0;
  color: #4cd697;
  font-size: 10px;
}

.read_only_input {
  background: #dddddd;
  border-radius: 6px;
  border: none;
  padding: 10px;
  width: 70%;
  height: 20px;
}

.read_only_input:focus {
  outline: none !important;
  border: 0.6px solid #d30000;
}

.field_input {
  background-color: #f4f2ff;
  border-radius: 6px;
  border: none;
  padding: 10px;
  width: 70%;
  height: 20px;
}

.field_input:focus {
  outline: none !important;
  border: 0.6px solid #6640ff;
}

.field_description {
  font-size: 12px;
  color: #8e8e93;
  width: 74%;
  box-sizing: border-box;
}
