.button_component {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  margin-right: 5px;
  width: 100%;
  display: flex;
  justify-content: center;

}

.icon_container {
  display: flex;
  justify-self: flex-start;
  margin: 3px;
}

.add_button {
  cursor: pointer;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  color: #fff;

  margin: 5px;
  padding: 3px;

  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  min-height: 30px;
  min-width: 100%;

  background: #4CD697;
  color: #fff;
  font-weight: 550;
  font-size: 12px;
}

.add_button:hover {
  transition: all 0.2s ease-in-out;
  background: #4CD697;
  color: #fff;
}

.delete_button {
  cursor: pointer;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;

  margin: 5px;
  padding: 3px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  min-height: 30px;
  min-width: 100%;

  background: #f44336;
  color: #fff;
  font-weight: 550;
  font-size: 12px;
}

.delete_button:hover {
  transition: all 0.2s ease-in-out;
  background: #f44336;
  color: #fff;
}

.filter_button {
  cursor: pointer;
  outline: none;
  border: none;
  cursor: pointer;
  background: none;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-radius: 5px;
  min-height: 30px;

  background: #6640ff;
  color: #fff;
}

.filter_button:hover {
  transition: all 0.2s ease-in-out;
  background: #6640ff;
  color: #fff;
}

