.MuiDrawer-paper {
  margin-top: 80px;
  border-radius: 14px;
  margin-left: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.go_back_container {
  appearance: none;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;
}

.go_back_p {
  padding: 0;
  margin: 0;
  justify-self: center;
  align-self: center;
  font-size: 12px;
  color: #6640ff;
}

.title_sidebar_container {
  text-align: left;
  padding: 5px;
}

.title_sidebar {
  color: #6640ff;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
}

.navLink {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  outline: 0;
  text-decoration: none;
}
