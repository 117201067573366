.welcome_page {
  width: 98%;
  min-height: 100vh;
  border-radius: 14px;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.welcome_title {
  font-size: 35px;
  padding: 0 15px 15px 15px;
}

.welcome_text_container {
  padding: 0 15px 15px 15px;
  width: 50%;
}

.welcome_text {
  font-size: 15px;
  color: black;
}

.welcome_img_container {
  margin-top: 10%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
