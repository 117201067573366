.create_view {
  width: -webkit-fill-available;
  margin-right: 15px;
  min-width: 100vh;
  height: 92%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  overflow: hidden;

  border-radius: 14px;
  padding: 20px 30px 20px 30px;
  background-color: #fff;
  box-sizing: border-box;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

/* TOP SECTION */

.top_container {
  display: flex;
  flex-direction: column;
}

.icon_button_detailview {
  appearance: none;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
  align-self: flex-start;
}

.title_top_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title_top_container button {
  margin-right: 10px;
}

.title_top {
  color: #000;
  font-weight: 500;
  font-size: 20px;
  width: 70%;
}

/* FIELDS SECTION */

.scroll_container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/* total width */
.scroll_container::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.scroll_container::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.scroll_container::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

.field_flex_column {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub_title {
  color: black;
}

.field_button_container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.field_save_button {
  width: auto;
  background: #4cd697;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.field_create_button {
  width: auto;
  background: #6640ff;
  color: #fff;
  padding: 7px 15px 7px 15px;
  appearance: none;
  outline: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}
