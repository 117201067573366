.alert_container {
  display: flex;
  flex-direction: row;
}

.error_response_field {
  margin: 0;
  margin-right: 5px;
  padding: 0;
  font-weight: bold;
}

.error_response {
  margin: 0;
  margin-right: 10px;
  padding: 0;
}
