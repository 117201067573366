.filter_warning_component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img_container {
  padding-left: 110px;
  padding-right: 110px;
  padding-top: 110px;
}

.filter_warning_img {
  width: 400px;
  height: auto;
}

.filter_warning_text {
  font-size: 20px;
  color: #000;
  padding-bottom: 160px;
}


