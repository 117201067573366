.dashboardPage {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
  box-sizing: border-box;
  overflow: hidden;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.flex-column {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}








