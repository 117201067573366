.search_component {
  max-width: 100%;
  margin-right: 15px;
  max-height: 5%;
  border-radius: 14px;
  background-color: #fff;
  box-sizing: border-box;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  box-sizing: border-box;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.search_filter_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.button_filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #6640ff;

  background: none;
  color: inherit;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.button_filter_text {
  font-size: 15px;
  padding: 7px;
}

.search_bar_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 15px;
  width: 60%;
}

.search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f2ff;
  border-radius: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 22px;
  width: 100%;
  min-width: 70%;
}

.input_search {
  outline: none;
  background-color: #f4f2ff;
  -webkit-appearance: none;
  border-radius: 20px;
  box-shadow: none;
  appearance: none;
  border: none;
  margin-left: 8px;
  color: #000;
  width: 100%;
}

.input_search:focus {
  outline: none;
  border: none;
}

.buttons_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 20%;
}
