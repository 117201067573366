.crudTable {
  min-height: 100vh;
  margin-right: 15px;
  margin-top: 5px;
  border-radius: 14px;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.columns_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}

.tables_container {
  display: flex;
  flex-direction: column;
}

.pages_manager_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.pages_manager_row_container {
  display: flex;
  flex-direction: row;
}

.pages_manager_property {
  padding: 5px;
  align-self: center;
}

.pages_manager_text {
  font-size: 10px;
  color: #6e6893;
}

.icon_button {
  appearance: none;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
}
