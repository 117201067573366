.modal_title {
  padding: 10px;
  margin: 0;
}

.objdata_container {
  display: flex;
  flex-direction: column;
  padding: 10px; 
  margin-top: 15px;
  margin-bottom: 15px;
}

.modal_button_container {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-around;
}

.property_container {
  display: flex;
  flex-direction: row;
}

.key_text {
  color: #6E6893;
  margin: 5px;
}

.value_text {
  color: #000;
  margin: 5px;
}

.action_description {
  font-size: 12px;
  padding: 10px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}
