.column {
  min-width: 20%;
  box-sizing: border-box;
}

.column_text {
  color: #6640ff;
  font-size: 15px;
  font-weight: 500;
}
