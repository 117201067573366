.cardLink_component {
  padding: 5px;
  margin-bottom: 30px;
  margin-top: 10px;


  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardLink_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  padding: 8px 8px 8px 8px;

  background-color: #f4f2ff;
  min-width:80%;
  max-width: 80%;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.cardLink {
  display: flex;
  flex-direction: column;
  min-width: 20%;
  max-width: 20%;
  margin: 0;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
}

.cardLink_key {
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 10px;
}

.pk_link {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  color: #6640FF;
  font-weight: 500;
  margin-bottom: 10px;
}

.cardLink_value {
  color: #000;
  color: #6640FF;
  font-weight: 500;
  margin: 0;
  margin-bottom: 10px;
}
