.row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  padding: 8px 12px 8px 12px;
}

.row_field {
  min-width: 20%;
  box-sizing: border-box;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-height: X;       
  max-height: X*2;      
}

.row_field_text {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  max-width: 30em;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row_actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}

.icon_button {
  appearance: none;
  outline: none;
  cursor: pointer;
  background: none;
  border: none;
}
