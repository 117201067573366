:root {
  --background: #e4e4e4;
  --card: #fc9208;
  --back: #000;
  --white: #fff;
  --yellow: #fdbc2c;
  --yellow_low_opacity: rgba(253, 188, 44, 0.7);
  --purple: #6640ff;
  --purple_low_opacity: rgba(86, 46, 246, 0.71);
  --green: #4cd697;
  --grey: #6e6893;
  --grey_field: #dddddd;
}

html {
  min-height: 100%;
}

body {
  min-height: 100vh;
  background-color: #e4e4e4;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
