.container {
  display: flex;
  justify-content: space-between;
  align-items: center;

    padding: 12px;
    background-color: #fff;
}

.image {
  max-width: 200px;
  height: auto;
}
